import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import Map from "../../components/Map"
import ContactCard from "../../components/ContactCard"
import Inner from "../../components/Inner"
import Button from "../../components/Button"
import { FaPaperPlane } from "react-icons/fa"

import * as Color from "../../utils/color"
import { mobile, tablet, desktop } from "../../utils/breakpoint"

import { contacts } from "../../meta/contacts"

const Contact = styled.section``

const Content = styled.div`
	padding: 2em 0;
	display: grid;
	grid-template-areas:
		"methods"
		"map"
		"request";
	grid-auto-columns: 1fr;
	grid-row-gap: 4em;
`

const Header = styled.div`
	${tw`mb-8`}
`

const MapContent = styled.div`
	grid-area: map;
	width: 100%;
	height: 100%;

	${Header} {
		text-align: center;
	}
`

const Methods = styled.div`
	grid-area: methods;
`

const List = styled.div`
	display: grid;
	grid-gap: 16px;

	@media ${tablet} {
		grid-template-columns: repeat(2, 1fr);
	}

	@media ${desktop} {
		grid-template-columns: repeat(4, 1fr);
	}
`

const Request = styled.div`
	grid-area: request;

	${Header} {
		text-align: center;
	}
`

const Title = styled.h3`
	${tw`text-4xl md:text-5xl leading-tight mb-2`}
	font-weight: bold;
	color: ${Color.primary};
`

const Detail = styled.p`
	${tw`text-base md:text-lg`}
	color: ${Color.primary};

`

const Input = styled.input`
	&[type="text"] {
		padding: 1rem;
		background-color: white;
		border: solid 1px lightgray;
		border-radius: 8px;
	}
`

const TextArea = styled.textarea`
	min-width: 100%;
	min-height: 64px;
	padding: 1rem;
	background-color: white;
	border: solid 1px lightgray;
	border-radius: 8px;
	resize: vertical;
`

const Form = styled.form`
	display: grid;
	grid-gap: 1em;

	@media ${mobile} {
		grid-template-areas:
			"name name"
			"email email"
			"subject subject"
			"description description"
			"button button";
	}

	@media ${tablet} {
		grid-template-areas:
			"name name"
			"email email"
			"subject subject"
			"description description"
			"button button";
	}

	@media ${desktop} {
		grid-template-areas:
			"name email"
			"subject subject"
			"description description"
			"button button";
	}

	#name {
		grid-area: name;
	}

	#email {
		grid-area: email;
	}

	#subject {
		grid-area: subject;
	}

	#description {
		grid-area: description;
	}

	#submit {
		grid-area: button;
	}

	${Button} {
		display: flex;
		justify-content: center;
		align-items: center;

		width: ${props => props.width}px;

		span:first-of-type {
			margin-right: 0.8em;
		}
	}

	@media ${tablet} {
		${Button} {
			width: 420px;
		}
	}

	@media ${desktop} {
		${Button} {
			width: 320px;
		}
	}
`

const ContactSection = () => {
	const submitHandler = event => {
		event.preventDefault()
	}

	return (
		<Contact>
			<Content>
				<Methods>
					<Inner>
						<Header>
							<Title>How can we assist you?</Title>
							<Detail>If you have any questions, please contact us via these methods.</Detail>
						</Header>
						<List>
							{contacts.map((item, index) => (
								<ContactCard key={index} {...item} />
							))}
						</List>
					</Inner>
				</Methods>
				<MapContent>
					<Header>
						<Title>Location</Title>
						<Detail>Come to our location.</Detail>
					</Header>
					<Map />
				</MapContent>
				<Request>
					<Inner>
						<Header>
							<Title>Form Us</Title>
							<Detail>Or, just tell us from here.</Detail>
						</Header>
						<Form>
							<Input id="name" type="text" placeholder="Your Name" />
							<Input id="email" type="text" placeholder="Your Email" />
							<Input id="subject" type="text" placeholder="Your Subject" />
							<TextArea id="description" placeholder="Your Description" />
							<Button id="submit" onClick={submitHandler} aria-label={"submit form"}>
								<span>Submit</span>
								<span>
									<FaPaperPlane size={18} />
								</span>
							</Button>
						</Form>
					</Inner>
				</Request>
			</Content>
		</Contact>
	)
}

export default ContactSection
