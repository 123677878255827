import React from "react"
import ContactUs from "../sections/contact-us"

const Wrapper = () => {
	return (
		<>
			<ContactUs />
		</>
	)
}

export default Wrapper
