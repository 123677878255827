import React, { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

mapboxgl.accessToken = process.env.MAP_BOX_ACCESS_TOKEN

const Map = styled.div`
	height: 400px;
`

const MapWrapper = () => {
	const old_lng = 104.9207
	const old_lat = 11.562
	const lat = 11.6526881
	const lng = 104.9118573
	const zoom = 18.0

	const [map, setMap] = useState(null)
	const mapContainer = useRef(null)

	useEffect(() => {
		const initializeMap = ({ setMap, mapContainer }) => {
			const map = new mapboxgl.Map({
				container: mapContainer.current,
				style: "mapbox://styles/mapbox/streets-v11",
				center: [lng, lat],
				zoom: zoom,
			})

			map.on("load", () => {
				setMap(map)
				map.resize()
			})
		}

		if (!map) initializeMap({ setMap, mapContainer })
	}, [map])

	return <Map ref={el => (mapContainer.current = el)}></Map>
}

export default MapWrapper
