import React from "react"
import {
	FaMailBulk,
	FaHeadset,
	FaFacebookMessenger,
	FaStoreAlt,
} from "react-icons/fa"

export let contacts = [
	{
		icon: <FaMailBulk size={42} />,
		method: "Email Us",
		detail: "We will reply in 24 hours",
		additional: "info@arrowdot.io",
	},
	{
		icon: <FaHeadset size={42} />,
		method: "Phone",
		detail: "8am to 5am PST",
		additional: "(+855)98 98 19 94 and (+855)99 98 19 94",
	},
	{
		icon: <FaFacebookMessenger size={42} />,
		method: "Message",
		detail: "We reach out asap",
		additional: "",
	},
	{
		icon: <FaStoreAlt size={42} />,
		method: "Visit Us",
		detail: "At Innovation Center NIPTICT on 1st floor",
		additional: "",
	},
]
