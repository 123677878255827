import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import { FaRegFileImage } from "react-icons/fa"

import * as Color from "../../utils/color"

const IconHolder = styled.div`
	${tw`mb-2`}
	align-self: center;

	svg {
		fill: ${Color.primary};
	}
`

const Title = styled.h5`
	${tw`text-base md:text-lg`}
	font-weight: bold;
	align-self: center;
	color: ${Color.primary};
`

const Detail = styled.p`
	${tw`text-xs md:text-sm`}

	height: 0.85rem;
	align-self: center;
	text-align: center;
	color: ${Color.primary};
`

const Contact = styled.div`
	min-height: 200px;
	cursor: auto;
	user-select: none;
	padding: 0 1rem;
	background-color: white;
	border-radius: 8px;
	border: solid 1px lightgray;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&:hover {
		background-color: ${Color.primary};
		border: none;

		${IconHolder} {
			svg {
				fill: white;
			}
		}

		${Title} {
			color: white;
		}

		${Detail} {
			color: white;
		}
	}
`

const ContactCard = ({ method, detail, icon = <FaRegFileImage size={32} />, ...props }) => {
	return (
		<Contact {...props}>
			<IconHolder>{icon}</IconHolder>
			<Title>{method}</Title>
			<Detail>{detail}</Detail>
		</Contact>
	)
}

export default styled(ContactCard)``
